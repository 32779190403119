/* NORMAL */

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Hairline-Trial.woff) format("woff"),
    url(./Theinhardt-Hairline-Trial.woff2) format("woff2"),
    url(./Theinhardt-Hairline-Trial.otf) format("otf");
  font-weight: 100;
}

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Ultralight-Trial.woff) format("woff"),
    url(./Theinhardt-Ultralight-Trial.woff2) format("woff2"),
    url(./Theinhardt-Ultralight-Trial.otf) format("otf");
  font-weight: 200;
}

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Thin-Trial.woff) format("woff"),
    url(./Theinhardt-Thin-Trial.woff2) format("woff2"),
    url(./Theinhardt-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Light-Trial.woff) format("woff"),
    url(./Theinhardt-Light-Trial.woff2) format("woff2"),
    url(./Theinhardt-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Regular-Trial.woff) format("woff"),
    url(./Theinhardt-Regular-Trial.woff2) format("woff2"),
    url(./Theinhardt-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Medium-Trial.woff) format("woff"),
    url(./Theinhardt-Medium-Trial.woff2) format("woff2"),
    url(./Theinhardt-Medium-Trial.otf) format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Bold-Trial.woff) format("woff"),
    url(./Theinhardt-Bold-Trial.woff2) format("woff2"),
    url(./Theinhardt-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Heavy-Trial.woff) format("woff"),
    url(./Theinhardt-Heavy-Trial.woff2) format("woff2"),
    url(./Theinhardt-Heavy-Trial.otf) format("otf");
  font-weight: 800;
}

@font-face {
  font-family: Theinhardt;
  src: url(./Theinhardt-Black-Trial.woff) format("woff"),
    url(./Theinhardt-Black-Trial.woff2) format("woff2"),
    url(./Theinhardt-Black-Trial.otf) format("otf");
  font-weight: 900;
}

/* COMPACT */

@font-face {
  font-family: Theinhardt-Compact;
  src: url(./Theinhardt-Compact-Hairline-Trial.woff) format("woff"),
    url(./Theinhardt-Compact-Hairline-Trial.woff2) format("woff2"),
    url(./Theinhardt-Compact-Hairline-Trial.otf) format("otf");
  font-weight: 100;
}

@font-face {
  font-family: Theinhardt-Compact;
  src: url(./Theinhardt-Compact-Ultralight-Trial.woff) format("woff"),
    url(./Theinhardt-Compact-Ultralight-Trial.woff2) format("woff2"),
    url(./Theinhardt-Compact-Ultralight-Trial.otf) format("otf");
  font-weight: 200;
}

@font-face {
  font-family: Theinhardt-Compact;
  src: url(./Theinhardt-Compact-Thin-Trial.woff) format("woff"),
    url(./Theinhardt-Compact-Thin-Trial.woff2) format("woff2"),
    url(./Theinhardt-Compact-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt-Compact;
  src: url(./Theinhardt-Compact-Light-Trial.woff) format("woff"),
    url(./Theinhardt-Compact-Light-Trial.woff2) format("woff2"),
    url(./Theinhardt-Compact-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt-Compact;
  src: url(./Theinhardt-Compact-Regular-Trial.woff) format("woff"),
    url(./Theinhardt-Compact-Regular-Trial.woff2) format("woff2"),
    url(./Theinhardt-Compact-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt-Compact;
  src: url(./Theinhardt-Compact-Medium-Trial.woff) format("woff"),
    url(./Theinhardt-Compact-Medium-Trial.woff2) format("woff2"),
    url(./Theinhardt-Compact-Medium-Trial.otf) format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt-Compact;
  src: url(./Theinhardt-Compact-Bold-Trial.woff) format("woff"),
    url(./Theinhardt-Compact-Bold-Trial.woff2) format("woff2"),
    url(./Theinhardt-Compact-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt-Compact;
  src: url(./Theinhardt-Compact-Heavy-Trial.woff) format("woff"),
    url(./Theinhardt-Compact-Heavy-Trial.woff2) format("woff2"),
    url(./Theinhardt-Compact-Heavy-Trial.otf) format("otf");
  font-weight: 800;
}

/* CONDENSED */

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Hairline-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Hairline-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Hairline-Trial.otf) format("otf");
  font-weight: 100;
}

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Ultralight-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Ultralight-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Ultralight-Trial.otf) format("otf");
  font-weight: 200;
}

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Thin-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Thin-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Light-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Light-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Regular-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Regular-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Medium-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Medium-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Medium-Trial.otf) format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Bold-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Bold-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Heavy-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Heavy-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Heavy-Trial.otf) format("otf");
  font-weight: 800;
}

@font-face {
  font-family: Theinhardt-Condensed;
  src: url(./Theinhardt-Condensed-Black-Trial.woff) format("woff"),
    url(./Theinhardt-Condensed-Black-Trial.woff2) format("woff2"),
    url(./Theinhardt-Condensed-Black-Trial.otf) format("otf");
  font-weight: 900;
}

/* EXTENDED */

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Hairline-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Hairline-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Hairline-Trial.otf) format("otf");
  font-weight: 100;
}

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Ultralight-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Ultralight-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Ultralight-Trial.otf) format("otf");
  font-weight: 200;
}

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Thin-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Thin-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Light-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Light-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Regular-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Regular-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Medium-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Medium-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Medium-Trial.otf) format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Bold-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Bold-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Heavy-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Heavy-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Heavy-Trial.otf) format("otf");
  font-weight: 800;
}

@font-face {
  font-family: Theinhardt-Extended;
  src: url(./Theinhardt-Extended-Black-Trial.woff) format("woff"),
    url(./Theinhardt-Extended-Black-Trial.woff2) format("woff2"),
    url(./Theinhardt-Extended-Black-Trial.otf) format("otf");
  font-weight: 900;
}

/* MONO */

@font-face {
  font-family: Theinhardt-Mono;
  src: url(./Theinhardt-Mono-Thin-Trial.woff) format("woff"),
    url(./Theinhardt-Mono-Thin-Trial.woff2) format("woff2"),
    url(./Theinhardt-Mono-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt-Mono;
  src: url(./Theinhardt-Mono-Light-Trial.woff) format("woff"),
    url(./Theinhardt-Mono-Light-Trial.woff2) format("woff2"),
    url(./Theinhardt-Mono-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt-Mono;
  src: url(./Theinhardt-Mono-Regular-Trial.woff) format("woff"),
    url(./Theinhardt-Mono-Regular-Trial.woff2) format("woff2"),
    url(./Theinhardt-Mono-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt-Mono;
  src: url(./Theinhardt-Mono-Medium-Trial.woff) format("woff"),
    url(./Theinhardt-Mono-Medium-Trial.woff2) format("woff2"),
    url(./Theinhardt-Mono-Medium-Trial.otf) format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt-Mono;
  src: url(./Theinhardt-Mono-Bold-Trial.woff) format("woff"),
    url(./Theinhardt-Mono-Bold-Trial.woff2) format("woff2"),
    url(./Theinhardt-Mono-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt-Mono;
  src: url(./Theinhardt-Mono-Heavy-Trial.woff) format("woff"),
    url(./Theinhardt-Mono-Heavy-Trial.woff2) format("woff2"),
    url(./Theinhardt-Mono-Heavy-Trial.otf) format("otf");
  font-weight: 800;
}

/* SEMI CONDENSED */

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Hairline-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Hairline-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Hairline-Trial.otf) format("otf");
  font-weight: 100;
}

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Ultralight-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Ultralight-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Ultralight-Trial.otf) format("otf");
  font-weight: 200;
}

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Thin-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Thin-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Light-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Light-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Regular-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Regular-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Medium-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Medium-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Medium-Trial.otf) format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Bold-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Bold-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Heavy-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Heavy-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Heavy-Trial.otf) format("otf");
  font-weight: 800;
}

@font-face {
  font-family: Theinhardt-SemiCondensed;
  src: url(./Theinhardt-SemiCondensed-Black-Trial.woff) format("woff"),
    url(./Theinhardt-SemiCondensed-Black-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiCondensed-Black-Trial.otf) format("otf");
  font-weight: 900;
}

/* SEMI EXTENDED */

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Hairline-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Hairline-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Hairline-Trial.otf) format("otf");
  font-weight: 100;
}

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Ultralight-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Ultralight-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Ultralight-Trial.otf) format("otf");
  font-weight: 200;
}

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Thin-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Thin-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Light-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Light-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Regular-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Regular-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Medium-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Medium-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Medium-Trial.otf) format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Bold-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Bold-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Heavy-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Heavy-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Heavy-Trial.otf) format("otf");
  font-weight: 800;
}

@font-face {
  font-family: Theinhardt-SemiExtended;
  src: url(./Theinhardt-SemiExtended-Black-Trial.woff) format("woff"),
    url(./Theinhardt-SemiExtended-Black-Trial.woff2) format("woff2"),
    url(./Theinhardt-SemiExtended-Black-Trial.otf) format("otf");
  font-weight: 900;
}